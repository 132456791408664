import { consoleSandbox } from "@sentry/utils"
import React from "react"
import {Card, Container, Row, Col, Dropdown} from 'react-bootstrap' 
import DelayEntry from "../../DelayEntry"

export default class PenListComponent extends React.Component {

  constructor(props) {
    super(props)

    this.feedlot_id = props.feedlot_id
    this.job_id = props.job_id
    this.searchInput = React.createRef()
    this.filterInput = React.createRef()
    this.is_admin = props.is_admin || false
    this.is_mobile = props.is_mobile || false

    this.sort_methods = [
      {value: "name", label: "Name"},
      {value: "status", label: "Status"},
      {value: "image", label: "Image"},
      {value: "yard_count", label: "Yard Count"},
      {value: "hc_count", label: "HC Count"},
      {value: "difference", label: "Difference"},
      {value: "issues", label: "Issues"},
    ]

    if (this.is_admin) {
      this.sort_methods.push({value: "passes", label: "Passes"})
    }
    
    this.state = {
      status: "Loading",
      page: null,
      total_pages: null,
      pens: [],
      selected_pen_id: null,
      sort_method: getCookie("pens_sort_method") || "name",
      sort_direction: getCookie("pens_sort_direction") || "asc",
    }

    this.getPens = this.getPens.bind(this)
    this.toMap = this.toMap.bind(this)

    this.listener = this.subscribe()
    this.localMessageSubscriber = this.subscribeToLocalMessages()

    this.currentWidth = window.innerWidth

    window.addEventListener("resize", () => {
      var previousWidth = this.currentWidth
      this.currentWidth = window.innerWidth
      if (this.currentWidth <= md_bp && previousWidth > md_bp) {
        this.forceUpdate()
      }
      if(this.currentWidth > md_bp && previousWidth <= md_bp) {
        this.forceUpdate()
      }
    });
  }

  componentWillUnmount() {
    this.listener?.unsubscribe()
  }

  render () {
    try {
      const total_pages = this.state.total_pages
      const sort_method = this.state.sort_method
      const sort_direction = this.state.sort_direction
      const page = this.state.page
      const is_admin = this.is_admin
      const fetchingStatus = this.state.status

      var condensed = this.currentWidth <= md_bp

      return (
        <Card>
          <Card.Header>
            <Container fluid className="p-0">
              <Row className="row-gap-2 col-gap-2">
                <Col xs={12} md="auto" className="p-0">
                  <Row className="row-gap-2 noflexwrap justify-content-between justify-content-md-start">
                    <Col xs="auto" className="p-0">
                      <Card.Title>
                        Pens
                        {fetchingStatus == 'Loading' && 
                          <DelayEntry>
                            <span className="d-inline-block">
                              <i className="fas fa-sync-alt spinning mx-2" data-tip data-for="fetching-pens-tooltip"/>
                              <ReactTooltip id='fetching-pens-tooltip' effect='solid'
                                textColor="black">
                                <span>Fetching Pens</span>
                              </ReactTooltip>
                            </span>
                          </DelayEntry>
                        }
                        {fetchingStatus == 'Failed' &&
                          <React.Fragment>
                            <i className="fas fa-exclamation-triangle text-danger mx-2" data-tip data-for="fetching-pens-failed-tooltip"/>
                            <ReactTooltip id='fetching-pens-failed-tooltip' effect='solid' textColor="black">
                              <span>Failed To Fetch Pens</span>
                            </ReactTooltip>
                          </React.Fragment>
                        }
                      </Card.Title>
                    </Col>
                    <Col xs="auto" className="p-0 ml-2">
                      <div id="list-map-toggler" className="btn-group nowrap">
                        <button className="btn btn-primary p-0 px-2 selected"
                          type="button" style={{fontSize: "1em"}}
                          data-tip data-for="to-list">
                          <i className="fas fa-list"/>
                        </button>
                        <button className="btn btn-primary p-0 px-2 unselected"
                          type="button" style={{fontSize: "1em"}}
                          data-tip data-for="to-map"
                          onClick={this.toMap}>
                          <i className="fas fa-map"/>
                        </button>
                      </div>
                      <ReactTooltip id='to-list' effect='solid'
                        textColor="black">
                        <span>Pen List</span>
                      </ReactTooltip>
                      <ReactTooltip id='to-map' effect='solid' 
                        textColor="black">
                        <span>Pen Map</span>
                      </ReactTooltip>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12" md className="p-0">
                  <Row className="justify-content-center justify-content-sm-between row-gap-2 col-gap-2">
                    {condensed == true && 
                      <Col xs="12" sm="auto" className="p-0 text-center text-md-left">
                        <div className="btn-group d-block">
                          {this.sort_methods.map((method,i) =>
                            <button 
                            key={method.value}
                            type="button" style={{fontSize: "1em"}}
                            className={"btn btn-primary text-nowrap p-0 px-1 " + (sort_method == method.value ? "selected" : "unselected")}
                            onClick={() => {
                              this.setState({
                                sort_method: method.value,
                                sort_direction: sort_method == method.value ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                              },() => {
                                this.getPens(page)
                              })
                            }}>
                              {method.label}
                            </button>
                          )}
                        </div>
                      </Col>
                    }
                    <Col xs="12" sm="auto" className="p-0">
                      <div className="input-group ml-auto mr-auto" style={{width: "fit-content"}}>
                        <input ref={this.searchInput} type="text" className="form-control card-search-input" placeholder="Search" onKeyDown={(e) => {e.key === "Enter" && this.getPens(0)}}/>
                        <div className="input-group-append"> 
                          <button className="input-group-text btn btn-primary" onClick={() => this.getPens(0)}>
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Card.Header>
          <Card.Body>
            {condensed == true && this.state.pens.map((pen,i) => 
              <PenComponent
              pen={pen}
              job_id={this.job_id} 
              feedlot_id={this.feedlot_id}
              selectPen={this.selectPen.bind(this,pen.id)}
              key={pen.id}
              is_admin = {is_admin}
              condensed={condensed}
              /> 
            )}
            {condensed == false &&
              <table className="component-table">
                <thead>
                  <tr>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "name",
                              sort_direction: state.sort_method == "name" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Name
                        {sort_method == "name" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "status",
                              sort_direction: state.sort_method == "status" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Status
                        {sort_method == "status" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "image",
                              sort_direction: state.sort_method == "image" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Image
                        {sort_method == "image" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "yard_count",
                              sort_direction: state.sort_method == "yard_count" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Yard Count
                        {sort_method == "yard_count" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "hc_count",
                              sort_direction: state.sort_method == "hc_count" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        HC Count
                        {sort_method == "hc_count" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "difference",
                              sort_direction: state.sort_method == "difference" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Difference
                        {sort_method == "difference" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    {is_admin == true &&
                      <React.Fragment>  
                        <td>
                          <button className="btn-icon text-light"
                            onClick={() => {
                              this.setState((state) => {
                                return {
                                  sort_method: "passes",
                                  sort_direction: state.sort_method == "passes" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                                }
                              }
                              ,() => {
                                this.getPens(page)
                              }
                            )}}>
                            Passes
                            {sort_method == "passes" &&
                              <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                            }
                          </button>
                        </td>
                        <td>
                          <button className="btn-icon text-light">
                            Corrections
                          </button>
                        </td>
                      </React.Fragment>
                    }
                    <td>
                      <button className="btn-icon text-light"
                        onClick={() => {
                          this.setState((state) => {
                            return {
                              sort_method: "issues",
                              sort_direction: state.sort_method == "issues" ? (sort_direction == "asc" ? "desc" : "asc") : "asc"
                            }
                          }
                          ,() => {
                            this.getPens(page)
                          }
                        )}}>
                        Issues
                        {sort_method == "issues" &&
                          <i className={"ml-2 fas " + (sort_direction == "asc" ? "fa-caret-down" : "fa-caret-up")}/>
                        }
                      </button>
                    </td>
                    <td> {/* Menu Icon */}

                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.pens.map((pen,i) => 
                    <PenComponent
                    pen={pen}
                    job_id={this.job_id} 
                    feedlot_id={this.feedlot_id}
                    selectPen={this.selectPen.bind(this,pen.id)}
                    key={pen.id}
                    is_admin = {is_admin}
                    condensed={condensed}
                    />
                  )}
                </tbody>
              </table>
            }

            
          </Card.Body>
          {total_pages != null && total_pages > 1 &&
            <Card.Footer className="pagination-section">
              <nav>
                <ul className="pagination m-0 pages-footer">
                  {[...Array(total_pages)].map((val, i) =>
                    <li className="page-item" key={i}><button className={"page-link" + (this.state.page == i ? " page-selected" : "")} onClick={() => this.getPens(i)}>{i+1}</button></li>
                  )}
                </ul>
              </nav>
            </Card.Footer>
          }
        </Card>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "JobDashboardChannels::PenListComponentChannel",
        feedlot_id: this.feedlot_id,
        job_id: this.job_id,
        is_admin: this.is_admin
      },
      {  
        connected() {
          _this.getPens(0)
        },

        received(data) {
          
          if (data.pens != null) {
            _this.setState({
              status: "Ok",
              page: data.page,
              total_pages: data.total_pages,
              pens: data.pens
            })
          }
          else if (data.message == "Getting Pens Failed") {
            _this.setState({
              status: "Failed"
            })
          }
          else if (data.pen != null) {
            _this.updatePen(data.pen)
          } else if (data.message == "pen_added") {
            _this.getPen(data.pen_id)
          } else if (data.message == "pen_removed") {
            _this.removePen(data.pen_id)
          } else if (data.message == "pen_updated") {
            if(_this.state.pens.filter((pen) => pen.id == data.pen_id).length > 0) {
              _this.getPen(data.pen_id)
            }
          }
        }
      }
    )
  }

  subscribeToLocalMessages() {
    var _this = this
    return postal.subscribe({
      channel: "PenListComponent",
      topic: "next_pen",
      callback: function(data, envelope) {
        for (var penIndex = 0; penIndex<_this.state.pens.length; penIndex++) {
          if (_this.state.pens[penIndex].id == _this.state.selected_pen_id) {
            var desiredIndex = penIndex
            if (data.previous == true) {
              desiredIndex = ((penIndex-1) + _this.state.pens.length)%_this.state.pens.length
            } else {
              desiredIndex = ((penIndex+1) + _this.state.pens.length)%_this.state.pens.length
            }
            _this.selectPen(_this.state.pens[desiredIndex].id)
            return
          }
        }  
      }
    });
  }

  getPens(page) {
    setCookie("pens_sort_method", this.state.sort_method, 360)
    setCookie("pens_sort_direction", this.state.sort_direction, 360)
    this.setState({
      status: "Loading"
    })
    page = page || 0
    let search = this.searchInput?.current?.value || ""
    this.listener.perform("get_page", {
      page: page,
      search: search,
      sort_method: this.state.sort_method,
      sort_direction: this.state.sort_direction
    })
  }

  getPen(pen_id) {
    this.listener.perform("get_pen", {
      pen_id: pen_id
    })
  }

  updatePen(updated_pen) {
    this.setState((state) => {
      var pens = this.state.pens
      var index = pens.findIndex((pen) => pen.id == updated_pen.id);
      if (index == -1) {
        pens = [updated_pen, ...pens]
      } else {
        pens[index] = updated_pen
      }
      return {
        pens: pens
      }
    })
  }

  removePen(pen_id) {
    this.setState((state) => {
      var pens = this.state.pens
      var index = pens.findIndex((pen) => pen.id == pen_id);
      if (index == -1) {

      } else {
        pens.splice(index,1)
      }
      return {
        pens: pens
      }
    })
  }

  selectPen(pen_id) {
    console.log("select pen ", pen_id)
    if (true) {//(pen_id != this.state.selected_pen_id) {
      this.setState({
        selected_pen_id: pen_id
      }, () => {
        postal.publish({
          channel: "PenDescriptionCard",
          topic: "pen_selection_changed",
          data: {
            pen_id: this.state.selected_pen_id
          }
        })
      })
    }
    //window.open(this.is_admin ? Routes.admin_pen_path(pen_id) : Routes.pen_path(pen_id), '_blank');
  }

  showFilters() {
    this.setState({
      showFilters: true,
      showSort: false
    })
  }

  showSort() {
    this.setState({
      showFilters: false,
      showSort: true
    })
  }

  toMap() {
    /*postal.publish({
      channel: "PensViewToggler",
      topic: "toggle",
      data: {
        message: "Map"
      }
    })*/
    if (this.is_admin) {
      window.location = Routes.admin_job_path(this.job_id)+"/?map"
    } else {
      window.location = Routes.job_path(this.job_id)+"/?map"
    }
  }
}

class PenComponent extends React.Component {

  constructor(props) {
    super(props);

    try {
      // static data
      this.feedlot_id = props.feedlot_id
      this.job_id = props.job_id
      this.is_admin = props.is_admin || false
      this.condensed = props.condensed || false
      this.pen_path = this.is_admin ? Routes.admin_pen_path(props.pen.id) : Routes.pen_path(props.pen.id)

      this.selectPen = () => {props.selectPen()}
      this.didClickPenName = this.didClickPenName.bind(this)

      this.state = {
        pen: props.pen,
        expanded: props.expanded || false
      }

      this.toggleExpand = this.toggleExpand.bind(this)

      this.editName = this.editName.bind(this)
      this.editYardCount = this.editYardCount.bind(this)
      this.setSkipQualityControl = this.setSkipQualityControl.bind(this)
      this.setStatus = this.setStatus.bind(this)
      this.setArchive = this.setArchive.bind(this)
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  // when pen is updated, update the state
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pen != this.state.pen) {
      this.setState({
        pen: nextProps.pen
      })
      return true
    }
    if (nextProps.condensed != this.condensed) {
      this.condensed = nextProps.condensed
      return true
    }
    return false
  }

  render () {
    try {
      var pen = this.state.pen
      var is_admin = this.is_admin
      var difference = (pen.count || 0 ) - (pen.yard_count || 0)

      var status_info = {title: "", color_class: ""}
      switch (pen.status) {
        case "new":
          status_info = {title: "In Progress", color_class: ""}
          break;
        case "complete":
          status_info = {title: "Complete", color_class: "text-success"}
          break;
        default:
          status_info = {title: "Issue - Alert Developer", color_class: "text-danger font-italic"}
          break;
      }

      if(pen.image_attached == false && pen.status != "complete") {
        status_info = {title: "Waiting For Image", color_class: ""} 
      }

      if(is_admin == true && pen.counting_status == "Queued") {
        status_info = {title: "CountThings Queued", color_class: ""} 
      } 

      var admin_issues = []
      if (is_admin == true) {
        if (pen.needs_reviewed) {
          admin_issues.push("Needs Reviewed")
        }

        if(pen.boundary_outside == true) {
          admin_issues.push("Boundary Outside")
        }
      }

      var issues = []
      if (pen.needs_reflown == true) {
        issues.push("Needs Reflown")
      }
      if (pen.gate_open == true) {
        issues.push("Gate Open")
      }
      if (pen.cattle_in_alley == true) {
        issues.push("Cattle In Alley")
      }

      issues = [...admin_issues, ...issues]

      if (this.condensed) { // mobile view
        return (
          <Container id="pen-row" fluid className="condensed" style={{position: "relative"}}>
            <Row className="justify-content-between text-light condensed-header">
              <Col>
                <button className="btn btn-link p-0 txt-lg link-light no-wrap" onClick={this.didClickPenName} onContextMenu={this.didClickPenName}>
                  {pen.name || "Undefined"}
                </button>
                {(is_admin == true && pen.comments.length > 0) &&
                  <React.Fragment>
                    <i 
                      className="fas fa-comment-alt ml-2"
                      data-tip data-for={"message-pen-tooltip-" + pen.id}/>
                    <ReactTooltip id={'message-pen-tooltip-'+pen.id} 
                      place="top"
                      effect='solid' 
                      overridePosition={({left, top}, currentEvent, currentTarget) => {
                        if (left < 0) {
                          left = 10
                        }
                        if (top < 0) {
                          top = 20
                        }
                        return {left, top}
                      }}
                      textColor="black">
                      <Container className="comments-container text-left">
                        {pen.comments.map((comment, i) => 
                          <Row>
                            <Col xs="auto">
                              <div className="text-primary">
                                {comment.user_name}:
                              </div>
                            </Col>
                            <Col>
                              {comment.message}
                            </Col>
                          </Row>
                        )}
                      </Container>
                    </ReactTooltip>
                  </React.Fragment>
                }
                {(is_admin == true && pen.ignore == true) &&
                  <React.Fragment>
                    <button 
                      className={"btn-icon text-light fas " + (pen.ignore ? "fa-eye-slash" : "fa-eye")} 
                      data-tip data-for={"ignore-pen-tooltip-" + pen.id}
                      onClick={()=>this.setSkipQualityControl(!pen.ignore)}/>

                    <ReactTooltip id={'ignore-pen-tooltip-'+pen.id} effect='solid' 
                      textColor="black">
                      <span>{pen.ignore ? "Add To Quality Control" : "Remove From Quality Control"}</span>
                    </ReactTooltip>
                  </React.Fragment>
                }
              </Col>
              <Col xs="auto" className="mr-3">
                <div className={"txt-lg no-wrap" + status_info.color_class}>
                  {status_info.title}
                </div>
              </Col>              
              {this.renderDropDownMenu({top: "0.2rem"})}
            </Row>
            <Row>
              <Col xs={6}>
                <table>
                  <tbody>
                    <tr>
                      <td className="pr-2 text-right">
                        Yard Count:
                      </td>
                      <td className="pr-2 text-center">
                        {pen.yard_count || 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-2 text-right">
                        HC Count:
                      </td>
                      <td className="pr-2 text-center">
                        {(is_admin == true || pen.status == "complete") && 
                          <React.Fragment>{pen.count || 0}</React.Fragment>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-2 text-right">
                        Difference:
                      </td>
                      <td className="pr-2 text-center">
                        {(is_admin == true || pen.status == "complete") && 
                          <React.Fragment>{difference || 0}</React.Fragment>
                        }
                      </td>
                    </tr>
                  
                    {is_admin == true && 
                      <tr>
                        <td className="pr-2 text-right">
                          Passes:
                        </td>
                        <td className="pr-2 text-center">
                          {pen.passes}
                        </td>
                      </tr>
                    }

                    { is_admin && pen.has_corrections &&
                      <tr>
                        <td className="pr-2 text-right">
                          Corrections:
                        </td>
                        <td className="pr-2 text-center">
                          { pen.has_corrections && 
                            <span className="text-success fas fa-check-circle"/>
                          }
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
              </Col>
              <Col xs={6} className="p-0">
                <table>
                  <tbody>
                    <tr>
                      <td className="pr-2 text-right">
                        Image:
                      </td>
                      <td className="pr-2 text-center">
                        {(pen.image_attached == true) &&
                          <i className="fas fa-check text-success"/>
                        }
                        {(pen.image_attached == false) &&
                          <i className="fas fa-times text-danger"/>
                        }
                      </td>
                    </tr>
                      <tr>
                        <td className="pr-2 text-right">
                          Gate Open:
                        </td>
                        <td className="pr-2 text-center">
                          {pen.gate_open == true && 
                            <i className="text-danger">Yes</i>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2 text-right">
                          Cattle In Alley:
                        </td>
                        <td className="pr-2 text-center">
                          {pen.cattle_in_alley == true && 
                            <i className="text-danger">Yes</i>
                          }
                        </td>
                      </tr>
                      { is_admin == true && pen.boundary_outside == true &&
                        <tr>
                          <td className="pr-2 text-right">
                            Boundary Outside:
                          </td>
                          <td className="pr-2 text-center">
                            {pen.boundary_outside == true && 
                              <i className="text-danger">Yes</i>
                            }
                          </td>
                        </tr>
                      }
                      {pen.needs_reflown &&
                        <tr>
                          <td className="px-2 text-center" colSpan={2}>
                            <i className="text-danger">Needs Reflown</i>
                          </td>
                        </tr>
                      }
                      {(is_admin == true && pen.needs_reviewed) &&
                        <tr>
                          <td className="px-2 text-center" colSpan={2}>
                            <i className="text-warning">Needs Reviewed</i>
                          </td>
                        </tr>
                      }
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        )
      } else { // desktop table view
        return (
          <tr id="pen-row">
            <td> {/* Name */}
              <button className="btn btn-link p-0" onClick={this.didClickPenName} onContextMenu={this.didClickPenName}>
                {pen.name || "Undefined"}
              </button>
            </td>
            <td className={status_info.color_class}> {/* Status */}
              {status_info.title}
            </td>
            <td> {/* Image */}
              {(pen.image_attached == true) &&
                <i className="fas fa-check text-success"/>
              }
              {(pen.image_attached == false) &&
                <i className="fas fa-times text-danger"/>
              }
            </td>
            <td> {/* Yard Count */}
              {pen.yard_count || 0}
            </td>
            <td> {/* HC Count */}
              {(is_admin == true || pen.status == "complete") && 
                <React.Fragment>{pen.count || 0}</React.Fragment>
              }
            </td>
            <td> {/* Difference */}
              {(is_admin == true || pen.status == "complete") && 
                <React.Fragment>{difference || 0}</React.Fragment>
              }
            </td>
            {is_admin == true && 
              <React.Fragment>
                <td>  {/* Passes */}
                  {pen.passes}
                </td>

                <td>  {/* Corrections */}
                  { pen.has_corrections && 
                    <span className="text-success fas fa-check-circle"/>
                  }
                </td>
              </React.Fragment>
              
            }
            <td> {/* Issues and menu button */}
              {issues.map((val, i) =>
                <div>
                  <i className="text-danger">
                    {val}
                  </i>
                </div>
                )}
            </td>
            <td className="menu"> {/* Menu */}
              {(is_admin == true && pen.comments.length > 0) &&
                <React.Fragment>
                  <i 
                    className="fas fa-comment-alt mr-2 "
                    data-tip data-for={"message-pen-tooltip-" + pen.id}/>
                  <ReactTooltip id={'message-pen-tooltip-'+pen.id} effect='solid' 
                    place="left"
                    overridePosition={({left, top}, currentEvent, currentTarget) => {
                      if (left < 0) {
                        left = 10
                      }
                      if (top < 0) {
                        top = 20
                      }
                      return {left, top}
                    }}
                    textColor="black">
                    <Container className="comments-container text-left">
                      {pen.comments.map((comment, i) => 
                        <Row>
                          <Col xs="auto">
                            <div className="text-primary">
                              {comment.user_name}:
                            </div>
                          </Col>
                          <Col>
                            {comment.message}
                          </Col>
                        </Row>
                      )}
                    </Container>
                  </ReactTooltip>
                </React.Fragment>
              }
              {(is_admin == true && pen.ignore == true) &&
                <React.Fragment>
                  <button 
                    className={"btn-icon fas " + (pen.ignore ? "fa-eye-slash" : "fa-eye")} 
                    data-tip data-for={"ignore-pen-tooltip-" + pen.id}
                    onClick={()=>this.setSkipQualityControl(!pen.ignore)}/>

                  <ReactTooltip id={'ignore-pen-tooltip-'+pen.id} effect='solid' 
                     

                    textColor="black">
                    <span>{pen.ignore ? "Add To Quality Control" : "Remove From Quality Control"}</span>
                  </ReactTooltip>
                </React.Fragment>
              }
              {this.renderDropDownMenu({position: "static"})}
            </td>
            
          </tr>
        )
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  didClickPenName(e) {
    if(e.type == "click") {
      this.selectPen()
    } else if(e.type == "contextmenu") {
      // open in new tab if right click
      e.preventDefault()
      e.stopPropagation()
      window.open(this.pen_path, "_blank")
    }
  }

  toggleExpand() {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  }

  editName() {
    postal.publish({
      channel: "EditPenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen.id,
        pen_name: this.state.pen.name,
        type: "pen_name"
      }
    })
  }

  editYardCount() {
    postal.publish({
      channel: "EditPenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen.id,
        pen_name: this.state.pen.name,
        type: "yard_count"
      }
    })
  }

  duplicatePen() {
    postal.publish({
      channel: "DuplicatePenModal",
      topic: "open",
      data: {
        pen_id: this.state.pen.id,
        pen_name: this.state.pen.name,
      }
    })
  }

  setSkipQualityControl(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.edit_skip_quality_control_admin_pen_path(this.state.pen.id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Skip Quality Control to ${bool_value}`)
        } else {
          postAlert("danger", `Failed To Set Skip Quality Control`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Skip Quality Control`)
      },
      complete: function() {}
    })
  }

  // true sets it to complete
  // false sets it to new
  setStatus(bool_value) {
    var jform = new FormData();
    jform.append('utf8',"&#x2713;");
    jform.append('authenticity_token',form_authenticity_token());
    jform.append('value', bool_value);
    
    $.ajax({
      url: Routes.edit_status_admin_pen_path(this.state.pen.id),
      type: "POST",
      data: jform,
      dataType: 'json',
      mimeType: 'multipart/form-data',
      contentType: false,
      cache: false,
      processData: false,
      success: function(response) {
        if (response.status == "Success") {
          postAlert("success", `Successfully Set Status to ${bool_value ? "Complete" : "New"}`)
        } else {
          postAlert("danger", `Failed To Set Status`)
        }
      },
      error: function(request, textStatus, errorThrown) {
        postAlert("danger", `Failed To Set Status`)
      },
      complete: function() {}
    })
  }

  setArchive() {
    if (ask("Are You Sure You Want To Archive This Pen?")) {
      var jform = new FormData();
      jform.append('utf8',"&#x2713;");
      jform.append('authenticity_token',form_authenticity_token());
      
      $.ajax({
        url: Routes.archive_admin_pen_path(this.state.pen.id),
        type: "POST",
        data: jform,
        dataType: 'json',
        mimeType: 'multipart/form-data',
        contentType: false,
        cache: false,
        processData: false,
        success: function(response) {
          if (response.status == "Success") {
            postAlert("success", `Successfully Archived Pen`)
          } else {
            postAlert("danger", `Failed To Archive Pen`)
          }
        },
        error: function(request, textStatus, errorThrown) {
          postAlert("danger", `Failed To Archive Pen`)
        },
        complete: function() {}
      })
    }
  }

  renderDropDownMenu(style = {}) {
    const pen = this.state.pen
    const is_admin = this.is_admin
    const pen_is_complete = pen.status == "complete"
    return (
      <React.Fragment>
        
        <Dropdown style={style}>
          <Dropdown.Toggle 
            as="i" 
            className="btn-icon fas fa-ellipsis-v dropdown-no-arrow txt-lg"
            bsPrefix="">
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={this.pen_path} target="_blank">Open Pen In New Tab</Dropdown.Item>
            <Dropdown.Item href={Routes.original_picture_pen_path(pen.id)} disabled={pen.image_attached == false} target="_blank">
              Open Original Image
            </Dropdown.Item>
            <Dropdown.Item onClick={this.editName}>
              Edit Pen Name
            </Dropdown.Item>
            <Dropdown.Item onClick={this.editYardCount}>
              Edit Yard Count
            </Dropdown.Item>
            { is_admin == true &&
              <Dropdown.Item href={Routes.admin_job_path(this.job_id, {map: true, focused_pen_id: pen.id})}>
                View On Map
              </Dropdown.Item>
            }
            { is_admin == false &&
              <Dropdown.Item href={Routes.job_path(this.job_id, {map: true, focused_pen_id: pen.id})}>
                View On Map
              </Dropdown.Item>
            }
            
            {is_admin == true &&
              <React.Fragment>
                <hr className="text-primary"/>
                <Dropdown.Item href={Routes.pen_locations_admin_job_path(this.job_id, {focused_pen_id: pen.id})}>
                  Pen Location
                </Dropdown.Item>
                <Dropdown.Item href={Routes.stats_admin_pen_path(pen.id)}>
                  History
                </Dropdown.Item>
                <Dropdown.Item 
                  className=""
                  onClick={()=>this.duplicatePen()}>
                  Duplicate Pen
                </Dropdown.Item>
                {/*<Dropdown.Item href={Routes.image_data_admin_pen_path(pen.id)}>
                  Image Data
                </Dropdown.Item>*/}
                {pen.archived == false &&
                  <Dropdown.Item 
                  className="text-danger"
                  onClick={this.setArchive}>
                  Archive
                  </Dropdown.Item>
                }
                
                <Dropdown.Item 
                  className=""
                  onClick={()=>this.setSkipQualityControl(!pen.ignore)}>
                  {pen.ignore ? "Add To Quality Control" : "Remove From Quality Control"}
                </Dropdown.Item>

                <Dropdown.Item 
                  className=""
                  onClick={()=>this.setStatus(!pen_is_complete)}>
                  Toggle Status
                </Dropdown.Item>
                
              </React.Fragment>
            }
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
      
    )
  }
}
