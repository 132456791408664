
import React from "react"

/*const Key = {
  // feedlot level activities
  feedlot_created: "feedlot_created",
  feedlot_removed: "feedlot_removed",
  team_member_invited: "team_member_invited",
  team_member_removed: "team_member_removed",
  dronelink_email_updated: "dronelink_email_updated",
  feedlot_name_updated: "feedlot_name_updated",
  kham_info_updated: "kham_info_updated",
  ref_pens_imported,
  micro_updated,

  // job level activities
  job_ordered,
  job_removed,
  began_photo_upload,
  photos_uploaded,
  flight_plan_ready,
  generated_dronelink_file,
  job_complete,
  job_under_review,
  job_finished_counting,
  job_name_updated,
  line_item_created,
  line_item_removed,
  line_item_sent_to_portal,
  upload_yard_count_csv,
  manually_created_pen,
  pen_removed,
  job_field_updated,

  // pen level activities
  pen_counted,
  pen_image_uploaded,
  pen_status_changed,
  pen_needs_reviewed_changed,
  pen_needs_reflown,
  pen_comment_added,
  pen_field_updated,
  pen_user_correction,
  pen_wrong_boundary,

  // general_tracker
  general_tracker
}

enum TrackableKey {
  Feedlot,
  Job,
  Pen
}*/


// inside a Container Row Col structure
export default function parseActivity(activity, parent_links = false, self_link = false, as_admin = false) {
  //client keys
  // feedlot - feedlot_removed, feedlot_added, share_removed, share_added
  // job - job_removed, job_added, job_completed, job_under_review

  // admin keys
  // user - user_removed, user_added
  var title
  var message
  var time = activity.created_at
  var asdf = null
  as_admin = activity.level == "admin" || as_admin
  
  var trackable_path = null
  switch (activity.trackable_type) {
    case "Feedlot":
      trackable_path = as_admin ? Routes.admin_feedlot_path(activity.trackable_id) : Routes.feedlot_path(activity.trackable_id)
      break;
    case "Job": 
      trackable_path = as_admin ? Routes.admin_job_path(activity.trackable_id) : Routes.job_path(activity.trackable_id)
      break;
    case "Pen":
      trackable_path = as_admin ? Routes.admin_pen_path(activity.trackable_id) : Routes.pen_path(activity.trackable_id)
      break;
  }

  switch(activity.key) {

    case "feedlot_created": // Feedlot Activity
      title = "Feedlot Created"
      message = (<span>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "feedlot_removed": // Feedlot Activity
      title = "Feedlot Removed"
      message = (<span>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "team_member_invited": // Feedlot Activity
      title = "Team Member Invited"
      var user_path = as_admin ? Routes.admin_user_path(activity.parameters.recipient_id) : null
      message = (<span>
        <b>
          <a href={user_path}>
            {activity.parameters.recipient_email}
          </a>
        </b>
        <br/>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "team_member_removed": // Feedlot Activity
      title = "Team Member Removed"
      var user_path = as_admin ? Routes.admin_user_path(activity.parameters.recipient_id) : null
      message = (<span>
        <b>
          <a href={user_path}>
            {activity.parameters.recipient_email}
          </a>
        </b>
        <br/>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "dronelink_email_updated": // Feedlot Activity
      title = "Dronelink Email Updated"
      message = (<span>
        <b>
          {activity.parameters.dronelink_email}
        </b>
        <br/>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "feedlot_name_updated": // Feedlot Activity
      title = "Feedlot Name Updated"
      message = (<span>
        <b>
          <a href={trackable_path}>
            {activity.parameters.feedlot_name}
          </a>
        </b>
        {culpritSection(as_admin)}
      </span>)
      break;

    case "kham_info_updated": // Feedlot Activity
      title = "Kham Info Updated"
      message = (<span>
        {`Kham Name: ${activity.parameters.kham_name}`}
        <br/>
        {`County: ${activity.parameters.location_name}`}
        <br/>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "ref_pens_imported": // Feedlot Activity
      title = "Ref Pens GeoJson Imported"
      message = (<span>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "micro_updated": // Feedlot Activity
      title = "Micro Connection Changed"
      message = (<span>
        {`Micro Connection: ${activity.parameters.is_micro ? "True" : "False"}`}
        <br/>
        {self_link == true && 
          <b>
            <a href={trackable_path}>
              {activity.parameters?.feedlot_name}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_ordered": // Job Activity
      title = "Job Ordered"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var has_flight_plan = activity.parameters.flight_plan_request_id
      var flight_plan_request_path = has_flight_plan == true && as_admin == true ? Routes.admin_flight_plan_reqeusts_path() : null
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {has_flight_plan == true && 
          <b>
            <a href={flight_plan_request_path}>
              {`${activity.parameters.requested_pen_count} Pens Requested`}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_removed": // Job Activity
      title = "Job Removed"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    // case "began_photo_upload": // Job Activity
    //   title = "User Began Uploading Photos"
    //   var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
    //   message = (<span>
    //     {self_link == true && 
    //       <div><b>
    //         <a href={trackable_path}>
    //           {activity.parameters.job_name}
    //         </a>
    //       </b></div>
    //     }
    //     {parent_links == true && 
    //       <div><b>
    //         <a href={feedlot_path}>
    //           {activity.parameters.feedlot_name}
    //         </a>
    //       </b></div>
    //     }
    //     {`Images to Upload: ${activity.parameters.count}`}
    //     {culpritSection(as_admin)}
    //   </span>)
    //   break;

    case "photos_uploaded": // Job Activity
      title = "User Finished Uploading Photos"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {`Successful Count: ${activity.parameters.successful_count}`}
        <br/>
        {`Errored Count: ${activity.parameters.error_count}`}
        <br/>
        {`Total Attempted: ${activity.parameters.total_count}`}
        {culpritSection(as_admin)}
      </span>)
      break;

    case "flight_plan_ready": // Job Activity
      title = "Flight Plan Ready"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "generated_dronelink_file": // Job Activity
      title = "Flight Plan Generated (Not Sent)"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var has_flight_plan = activity.parameters.flight_plan_request_id
      var flight_plan_request_path = has_flight_plan == true && as_admin == true ? Routes.generate_path_admin_job_path(activity.trackable_id) + `?flight_plan_request_id=${activity.parameters.flight_plan_request_id}` : null
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {has_flight_plan == true && 
          <b>
            <a href={flight_plan_request_path}>
              {`${activity.parameters.count} Pens`}
            </a>
          </b>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_complete": // Job Activity
      title = "Job Complete"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

      case "job_under_review": // Job Activity
      title = "Job Under Review"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_finished_counting": // Job Activity
      title = "Job Finished Counting"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_name_updated": // Job Activity
      title = "Job Name Updated"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "line_item_created": // Job Activity
      title = "Line Item Created"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        <div>
          <b>Fee: </b>
          {activity.fee}
        </div>
        <div>
          <b>Rate: </b>
          {activity.rate}
        </div>
        <div>
          <b>Count: </b>
          {activity.count}
        </div>
        {culpritSection(as_admin)}
      </span>)
      break;

    case "line_item_removed": // Job Activity
      title = "Line Item Removed"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        <div>
          <b>Fee: </b>
          {activity.fee}
        </div>
        <div>
          <b>Rate: </b>
          {activity.rate}
        </div>
        <div>
          <b>Count: </b>
          {activity.count}
        </div>
        {culpritSection(as_admin)}
      </span>)
      break;

    case "line_item_sent_to_portal": // Job Activity
      title = "Line Item Sent To Portal"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        <div>
          <b>Fee: </b>
          {activity.fee}
        </div>
        <div>
          <b>Rate: </b>
          {activity.rate}
        </div>
        <div>
          <b>Count: </b>
          {activity.count}
        </div>
        {culpritSection(as_admin)}
      </span>)
      break;

    case "upload_yard_count_csv": // Job Activity
      title = "Uploaded Yard Count CSV"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "manually_created_pen": // Job Activity
      title = "Manually Created Pen"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var pen_path = as_admin ? Routes.admin_pen_path(activity.parameters.pen_id) : Routes.pen_path(activity.parameters.pen_id)
      message = (<span>
        <b>
          <a href={pen_path}>
            {activity.parameters.pen_name}
          </a>
        </b>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_removed": // Job Activity
      title = "Pen Removed"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var pen_path = as_admin ? Routes.admin_pen_path(activity.parameters.pen_id) : Routes.pen_path(activity.parameters.pen_id)
      message = (<span>
        <b>
          <a href={pen_path}>
            {activity.parameters.pen_name}
          </a>
        </b>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "job_field_updated": // Job Activity
      title = `${activity.parameters.field_name} Updated to ${activity.parameters.field_value}`
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.job_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div><b>
            <a href={feedlot_path}>
              {activity.parameters.feedlot_name}
            </a>
          </b></div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_counted": // Pen Activity
      title = "Counted"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        <div>
          <b>{"Time Spent: "}</b>
          {activity.parameters.time_spent} Minutes
        </div>
        <div>
          <b>{"Detections Added: "}</b>
          {activity.parameters.detections_added}
        </div>
        <div>
          <b>{"Detections Removed: "}</b>
          {activity.parameters.detections_removed}
        </div>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_image_uploaded": // Pen Activity
      title = "Image Uploaded"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_status_changed": // Pen Activity
      title = `Pen Status Changed - ${activity.parameters.status}`
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_needs_reviewed_changed": // Pen Activity
      title = activity.parameters.needs_reviewed ? "Set As Needs Reviewed" : "Needs Reviewed Resolved"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_needs_reflown": // Pen Activity
      title = activity.parameters.needs_reflown ? "Pen Needs Reflown" : "Needs Reflown Resolved"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_comment_added": // Pen Activity
      title = "Comment Added"
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {activity.parameters.message}
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_field_updated": // Pen Activity
      title = `${activity.parameters.field_name} Updated to ${activity.parameters.field_value}`
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_user_correction": // Pen Activity
      title = `User Correction Made to Pen`
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;

    case "pen_wrong_boundary": // Pen Activity
      title = `User Alerted Wrong Boundary On Pen`
      var feedlot_path = as_admin ? Routes.admin_feedlot_path(activity.parameters.feedlot_id) : Routes.feedlot_path(activity.parameters.feedlot_id)
      var job_path = as_admin ? Routes.admin_job_path(activity.parameters.job_id) : Routes.job_path(activity.parameters.job_id)
      message = (<span>
        {self_link == true && 
          <div><b>
            <a href={trackable_path}>
              {activity.parameters.pen_name}
            </a>
          </b></div>
        }
        {parent_links == true && 
          <div>
            <div><b>
              <a href={job_path}>
                {activity.parameters.job_name}
              </a>
            </b></div>
            <div><b>
              <a href={feedlot_path}>
                {activity.parameters.feedlot_name}
              </a>
            </b></div>
          </div>
        }
        {culpritSection(as_admin)}
      </span>)
      break;


    case "general_tracker":
      var changes_keys = difference(Object.keys(activity.parameters.changes),["created_at", "updated_at"])
      title = (
        <React.Fragment>
          {activity.trackable_type + " Tracked: "}
          <a href={trackable_path}>
            {activity.trackable?.name || activity.trackable?.id}
          </a>
        </React.Fragment>
      )
      message = (<span>
        {activity.parameters.newly_added && 
          <React.Fragment>
            <b>Created</b>
            <br/>
          </React.Fragment>
        }
        {activity.parameters.destroyed && 
          <React.Fragment>
            <b>Destroyed</b>
            <br/>
          </React.Fragment>
        }
        
        {changes_keys.length > 0 && 
          <span style={{fontSize: "1em"}}>
            <b>Changes</b>
            {changes_keys.map((key,index) => 
              <div className="ml-3" key={key}> 
                <b>
                  {`${key}:  `}
                </b>
                <i>
                  {`${activity.parameters.changes[key][0].toString() || "Can't Display"} -> ${activity.parameters.changes[key][1].toString() || "Can't Display"}`}
                </i>
              </div>
            )}
          </span>
        }

        {culpritSection(as_admin, true)}
      </span>)
  }

  function culpritSection(as_admin = false, skipBreak = false) {
    var culprit = null
    var culprit_path = null
    if (activity.owner != null) {
      if (as_admin == false && activity.owner.system_admin == false) {
        culprit = activity.owner?.whole_name || activity.owner?.email
      } else {
        culprit = activity.owner?.whole_name || activity.owner?.email
        culprit_path = Routes.admin_user_path(activity.owner_id)
      }
    }
    
    if (culprit != null) {
      return (
        <div>
          By: <a href={culprit_path}>{activity?.owner?.whole_name || activity?.owner?.email}</a>
        </div>
      )
    } else {
      return null
    }
  }

  return {title, message, time}
}

