import React from "react"
import {Card, Container, Row, Col} from 'react-bootstrap' 

export default class FlightPlanStatus extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
        job_id: props.job_id,
        flight_plan_id: props.flight_plan_id,
        uuid: props.uuid,
        type: props.type, 
        messageStyle: props.message_style,
        fp_heart_beats: null,
        fp_status_info: null,
      }
  }

  componentDidMount() {
    if (this.state.type == "collector") {
      this.listener = this.subscribe()  
    }

    this.postalChannel = "FlightPlanStatusCollector_" + this.state.job_id

    if (this.state.type != "collector") {
      var _this = this
      this.status_info_subscription = postal.subscribe({
        channel: this.postalChannel,
        topic: "status_info_updated",
        callback: function(data, envelope) {
          _this.setState({fp_status_info: data.fp_status_info})
        }
      })
      this.hb_subscription = postal.subscribe({
        channel: this.postalChannel,
        topic: "hbs_updated",
        callback: function(data, envelope) {
          _this.setState({fp_heart_beats: data.fp_heart_beats})
        }
      })
    } else {
      this.status_info_subscription = postal.subscribe({
        channel: this.postalChannel,
        topic: "data_request",
        callback: function(data, envelope) {
          this.postalPublish("status_info_updated", {fp_status_info: this.state.fp_status_info})
          this.postalPublish("hbs_updated", {fp_heart_beats: this.state.fp_heart_beats})
        }
      })
    }

    this.key = this.postalChannel + `_${this.state.type}_${this.state.messageStyle}`
  }

  render () {

    try {

      const {fp_heart_beats, type, messageStyle, flight_plan_id, fp_status_info} = this.state

      if(type == "collector") {
        return null
      }
      
      return (
        <React.Fragment key={this.key}>
            {type == 'plan_exported' && fp_status_info != null && messageStyle == 'simple' &&
              <span>{fp_status_info.plan_created_boolean}</span>
            }

            {type == 'plan_exported' && fp_status_info != null && fp_status_info.flight_plan_requests != null && messageStyle == 'full' &&
              <React.Fragment>
                {fp_status_info.flight_plan_requests.map((fpr) => {
                  return (
                    <React.Fragment>
                      {fpr.plan_created == true && fpr.id == flight_plan_id && fpr.plan_created_by_user != null &&
                        <span>{fpr.plan_created_at} by {fpr.plan_created_by_user.name != null ? fpr.plan_created_by_user.name : fpr.plan_created_by_user.email}</span>
                      }

                      {fpr.plan_created == true && fpr.id == flight_plan_id && fpr.plan_created_by_user == null &&
                        <span>{fpr.plan_created_at}</span>
                      }
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
            }

            {type == 'plan_exported' && fp_status_info != null && fp_status_info.flight_plan_requests != null && messageStyle == 'alert' && 
                <React.Fragment>
                  {fp_status_info.flight_plan_requests.map((fpr) => {
                    return (
                      <React.Fragment>
                        {fpr.plan_created == true && fpr.id == flight_plan_id && fpr.plan_created_by_user != null &&
                          <div className="bg-info rounded py-1 px-4">
                            <span><b>Exported At: </b></span>
                            <span>{fpr.plan_created_at} by {fpr.plan_created_by_user.name != null ? fpr.plan_created_by_user.name : fpr.plan_created_by_user.email}</span>
                          </div>
                        }

                        {fpr.plan_created == true && fpr.id == flight_plan_id && fpr.plan_created_by_user == null &&
                          <div className="bg-info rounded py-1 px-4">
                            <span className="d-inline"><b>Exported At: </b></span>
                            <span>{fpr.plan_created_at}</span>
                          </div>
                        }
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
            }

            {type == 'notification_sent'  && fp_status_info != null && messageStyle == 'simple' &&
              <React.Fragment>
                { fp_status_info.sent_flight_plan_ready_date != null && 
                  <span>
                    {fp_status_info.notification_sent_by_user?.name || fp_status_info.notification_sent_by_user?.email || ""} - {fp_status_info.sent_flight_plan_ready_date}
                  </span>
                }
                { fp_status_info.sent_flight_plan_ready_date == null &&
                  <span>No</span>
                }
              </React.Fragment>
            }

            {type == 'notification_sent' && fp_status_info != null && messageStyle == 'full' &&
              <React.Fragment>
                { fp_status_info.sent_flight_plan_ready_date != null && 
                  <span>
                    {fp_status_info.sent_flight_plan_ready_date} {fp_status_info.notification_sent_by_user != null ? "by" : ""} {fp_status_info.notification_sent_by_user?.name || fp_status_info.notification_sent_by_user?.email || ""}
                  </span>
                }
                { fp_status_info.sent_flight_plan_ready_date == null &&
                  <span>No</span>
                }
              </React.Fragment>
            }

            {type == 'notification_sent' && fp_status_info != null && fp_status_info.sent_flight_plan_ready_date != null && messageStyle == 'alert' && 
              <div className="bg-info rounded py-1 px-4">
                <span className="d-inline"><b>Notifications Sent: </b></span>
                <span>{fp_status_info.sent_flight_plan_ready_date} {fp_status_info.notification_sent_by_user != null ? "by" : ""} {fp_status_info.notification_sent_by_user?.name || fp_status_info.notification_sent_by_user?.email || ""}</span>
              </div>
            }

            {type == 'active_users' && fp_heart_beats != null && messageStyle == 'simple' &&
                <React.Fragment>
                    {fp_heart_beats.map((hb) => {
                        return (
                            <React.Fragment>
                                {flight_plan_id != null && hb.flight_plan_id == flight_plan_id &&
                                    <div>
                                        <i className="fas fa-circle text-success mr-1"></i>
                                        {hb.name !== null ? (<span>{hb.name}</span>) : (<span>{hb.email}</span>)}
                                    </div>
                                }

                                {flight_plan_id == null &&
                                    <div>
                                        <i className="fas fa-circle text-success mr-1"></i>
                                        {hb.name !== null ? (<span>{hb.name}</span>) : (<span>{hb.email}</span>)}
                                    </div>
                                }
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            }

            {type == 'active_users' && fp_heart_beats != null && messageStyle == 'alert' &&
                <React.Fragment>
                    {fp_heart_beats.map((hb) => {
                        return (
                            <React.Fragment>
                                {this.flight_plan_id != null && hb.email != hb.current_user_email && hb.flight_plan_id == this.flight_plan_id &&
                                    <div className="text-center">
                                        <span className="alert-danger rounded p-2 mt-2 d-block">
                                            <i className="fas fa-exclamation-circle mr-1"></i>
                                            {hb.name != null ? <span>{hb.name} is currently accessing this plan</span> : <span>{hb.email} is currently accessing this plan</span>}
                                        </span>
                                    </div>
                                }
                                {this.flight_plan_id == null && hb.email != hb.current_user_email && 
                                    <div className="text-center">
                                        <span className="alert-danger rounded p-2 mt-2 d-block">
                                            <i className="fas fa-exclamation-circle mr-1"></i>
                                            {hb.name != null ? <span>{hb.name} is currently accessing this plan</span> : <span>{hb.email} is currently accessing this plan</span>}
                                        </span>
                                    </div>
                                }
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            }
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      return null
    }
  }

  postalPublish(topic, data) {
    postal.publish({
      channel: this.postalChannel,
      topic: topic,
      data: data
    })
  }

  subscribe() {
    var _this = this
    return App.cable.subscriptions.create(
      {
        channel: "AdminChannels::FlightPlanStatusChannel",
        job_id: this.state.job_id,
        flight_plan_request_id: this.state.flight_plan_id,
        uuid: this.state.uuid
      },
      {  
        connected() {
          _this.getJob()
          _this.getHeartBeats()
          

          if(_this.state.flight_plan_id != null && _this.state.uuid != null) {
            setInterval(function() {
              _this.checkIn()
            }, 30000)
          }
        },

        received(data) {
          if (data.job != null) {
            _this.updateFlightPlanInfo(data.job)
          } 
          
          if (data.heart_beats != null) {
            _this.updateHeartBeats(data.heart_beats)
          }

          if (data.message == "job_updated" || data.message == "flight_plan_updated") {
            _this.getJob()
          }

          if (data.message == "heart_beats_updated") {
            _this.getHeartBeats()
          }
        }
      }
    )
  }


  getJob() {
    this.listener.perform("get_job")
  }

  getHeartBeats() {
    this.listener.perform("get_heart_beats")
  }

  updateFlightPlanInfo(job) {
    // set state then updae postal
    this.setState({fp_status_info: job}, () => {
      this.postalPublish("status_info_updated", {fp_status_info: this.state.fp_status_info})
    })
  }

  updateHeartBeats(heart_beats) {
    this.setState({fp_heart_beats: heart_beats}, () => {
      this.postalPublish("hbs_updated", {fp_heart_beats: this.state.fp_heart_beats})
    })
  }

  checkIn() {
    this.listener.perform("check_in")
  }
}